import React, { useRef } from "react";
import { Link } from "gatsby";
import { FieldArray, Formik, Form } from "formik";
import * as Yup from "yup";

import "react-datepicker/dist/react-datepicker.css";

import { FormikControl } from "../Control";

import WhiteArrow from "../../../assets/images/svg/icons/arrows/arrow-w.svg";


const BraxiaSettlementForm = ({
  caseFormTitle
}) => {
  const twilioMailFunctionURL = 'https://firebrick-porpoise-4117.twil.io/knd-braxia';

  // const endpointSettlementInfo = "https://api.airtable.com/v0/appWxAoOPHl6AWLLr/tbl86wRYz4rUDPrUV";
  // const endpointTransactions = "https://api.airtable.com/v0/appWxAoOPHl6AWLLr/tblggFw772lOvRV3i";

  const ref = useRef();

  const USDtoCAD = 1.257;
  const EURtoCAD = 1.47;

  const initialValues = {
    // Identification
    name: '',
    address: '',
    secAddress: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    homePhone: '',
    workPhone: '',
    registration: '',
    email: '',

    // Identification of Claimant(s) and Relevant Accounts
    claimantBehalf: '',
    supportingDocs: [],
    jointAccount: '',
    authorityClaimantBehalf: '',
    numJointAccountHolders: 0,
    claimantNames: [
      {
        claimantName: '',
      },
    ],
    accountNumbers: [
      {
        accountNumber: '',
      },
    ],
    depositAccountName: '',
    isCanadianBank: '',
    canadianBankInstitutionNumber: '',
    canadianBankBranchNumber: '',
    canadianBankAccountNumber: '',
    internationalBankBankName: '',
    internationalBankBankAddress: '',
    internationalBankRoutingNumber: '',
    internationalBankAccountNumber: '',
    internationalBankSwiftCode: '',
    internationalBankIban: '',

    // Purchases and Sales of Shares
    transactions: [],

    // Calculation of Distribution, Maximum Entitlement, and Adjusted Damages

    // Primary Market
    /** 1   */ pmAcquiredShares: '',
    /** 2   */ pmNumberSharesAcquired: 0,
    /** 3   */ pmWeightedAveragePricePaidFeb17Mar2inCAD: 0.00,
    /** 4   */ pmStillHoldingAllShares: '',
    /** 5   */ pmMarketSharesDisposed: [],
    /** 6   */ pmSharesDisposedFeb17Mar2: 0,
    /** 7A  */ pmWeightedAveragePriceReceivedFeb17Mar2inUSD: 0.00,
    /** 8   */ pmLossFeb17Mar2: '0',
    /** 9   */ pmSharesDisposedMar3Mar11: 0,
    /** 10A */ pmWeightedAveragePriceReceivedMar3Mar11inUSD: 0.00,
    /** 11  */ pmLossMar3Mar11: '0',
    /** 12A */ pmSharesDisposedApr23May6: 0,
    /** 12B */ pmSharesDisposedApr27May11: 0,
    /** 12C */ pmSharesDisposedMar12Mar25: 0,
    /** 13A */ pmWeightedAveragePriceReceivedApr23May6inCAD: 0.00,
    /** 13B */ pmWeightedAveragePriceReceivedApr27May11inEUR: 0.00,
    /** 13C */ pmWeightedAveragePriceReceivedMar12Mar25inUSD: 0.00,
    /** 14A */ pmLossApr23May6: '0',
    /** 14B */ pmLossApr27May11: '0',
    /** 14C */ pmLossMar12Mar25: '0',
    /** 15A */ pmSharesDisposedAfterMay6: 0,
    /** 15B */ pmSharesDisposedAfterMay11: 0,
    /** 15C */ pmSharesDisposedAfterMar25: 0,
    /** 16A */ pmWeightedAveragePriceReceivedAfterMay6inCAD: 0.00,
    /** 16B */ pmWeightedAveragePriceReceivedAfterMay11inEUR: 0.00,
    /** 16C */ pmWeightedAveragePriceReceivedAfterMar25inUSD: 0.00,
    /** 17A */ pmLossAfterMay6: '0',
    /** 17B */ pmLossAfterMay11: '0',
    /** 17C */ pmLossAfterMar25: '0',
    /** 18  */ pmSharesStillHolding: 0,
    /** 19  */ pmLossStillHolding: '0',
    /** 20  */ pmMaxEntitlement: '0',
    /** 21  */ pmAdjustedDamages: '0',

    // Secondary Market
    /** 22  */ smAcquiredShares: '',
    /** 23  */ smMarketSharesAcquired: [],
    /** 24  */ smNumberSharesAcquired: 0,
    /** 24A */ smNumberSharesAcquiredCSE: 0,
    /** 24B */ smNumberSharesAcquiredFSE: 0,
    /** 24C */ smNumberSharesAcquiredOTC: 0,
    /** 25A */ smWeightedAveragePricePaidCSEinCAD: 0.00,
    /** 25B */ smWeightedAveragePricePaidFSEinEUR: 0.00,
    /** 25C */ smWeightedAveragePricePaidOTCinUSD: 0.00,
    /** 26  */ smStillHoldingAllShares: '',
    /** 27  */ smMarketSharesDisposed: [],
    /** 28  */ smSharesDisposedFeb17Mar2: 0,
    /** 29  */ smWeightedAveragePriceReceivedFeb17Mar2inUSD: 0.00,
    /** 31  */ smSharesDisposedMar3Mar11: 0,
    /** 32  */ smWeightedAveragePriceReceivedMar3Mar11inUSD: 0.00,
    /** 34A */ smSharesDisposedApr23May6: 0,
    /** 34B */ smSharesDisposedApr27May11: 0,
    /** 34C */ smSharesDisposedMar12Mar25: 0,
    /** 35A */ smWeightedAveragePriceReceivedApr23May6inCAD: 0.00,
    /** 35B */ smWeightedAveragePriceReceivedApr27May11inEUR: 0.00,
    /** 35C */ smWeightedAveragePriceReceivedMar12Mar25inUSD: 0.00,
    /** 37A */ smSharesDisposedAfterMay6: 0,
    /** 37B */ smSharesDisposedAfterMay11: 0,
    /** 37C */ smSharesDisposedAfterMar25: 0,
    /** 38A */ smWeightedAveragePriceReceivedAfterMay6inCAD: 0.00,
    /** 38B */ smWeightedAveragePriceReceivedAfterMay11inEUR: 0.00,
    /** 38C */ smWeightedAveragePriceReceivedAfterMar25inUSD: 0.00,
    /** 40A */ smSharesStillHoldingCSE: 0,
    /** 40B */ smSharesStillHoldingFSE: 0,
    /** 40C */ smSharesStillHoldingOTC: 0,
    /** 42  */ smMaxEntitlement: '0',
    /** 43  */ smAdjustedDamages: '0',

    // Total Loss
    /** 44  */ compensableLoss: '0',

    // Confirmation Items
    dataVerifiable: false,
    acceptedLegal: false
  };

  const validationSchema = Yup.object(
    {
      // Identification Schema
      name: Yup.string()
        .required('Required'),
      address: Yup.string()
        .required('Required'),
      secAddress: Yup.string(),
      city: Yup.string()
        .required('Required'),
      province: Yup.string()
        .required('Required'),
      postalCode: Yup.string()
        .required('Required'),
      country: Yup.string()
        .required('Required'),
      homePhone: Yup.string()
        .required('Required'),
      workPhone: Yup.string(),
      registration: Yup.string()
        .required('Required'),
      email: Yup.string()
        .required('Required')
        .email('Invalid email address.'),

      // Claimant Identification Schema
      claimantBehalf: Yup.string()
        .required('Required'),
      jointAccount: Yup.string()
        .required('Required'),
      authorityClaimantBehalf: Yup.string()
        .when('jointAccount', {
          is: ( jointAccount ) => jointAccount === "Yes",
          then: Yup.string()
            .test(
              'authorityClaimantBehalf',
              () => "You cannot submit this claim form if you cannot certify that you have the proper authority on behalf of the other owner(s) of the joint account.",
              ( val ) => val === "" || val === "Yes"
            )
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      numJointAccountHolders: Yup.number()
        .when('jointAccount', {
          is: ( jointAccount ) => jointAccount === "Yes",
          then: Yup.number()
            .test(
              'claimantNames',
              ( d ) => `The number of claimants must correspond to the number of joint account holders, in this case: ${ d.value }.`,
              ( val, ctx ) => val === ctx.parent.claimantNames.length
            )
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      claimantNames: Yup.array().of(
        Yup.object().shape({
          claimantName: Yup.string()
            .required('Required')
        })
        .test(
          'numJointAccountHolders',
          ( d ) => `The number of claimants must correspond to the number of joint account holders, in this case: ${ d.path }.`,
          ( val, ctx ) => val.length === ctx.parent.numJointAccountHolders
        )
      ),
      accountNumbers: Yup.array().of(
        Yup.object().shape({
          accountNumber: Yup.string()
            .required('Required')
        })
      ),
      depositAccountName: Yup.string()
        .required('Required'),
      canadianBankInstitutionNumber: Yup.string()
        .when('isCanadianBank', {
          is: ( isCanadianBank ) => isCanadianBank === "Yes",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      canadianBankBranchNumber: Yup.string()
        .when('isCanadianBank', {
          is: ( isCanadianBank ) => isCanadianBank === "Yes",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      canadianBankAccountNumber: Yup.string()
        .when('isCanadianBank', {
          is: ( isCanadianBank ) => isCanadianBank === "Yes",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      internationalBankBankName: Yup.string()
        .when('isCanadianBank', {
          is: ( isCanadianBank ) => isCanadianBank === "No",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      internationalBankBankAddress: Yup.string()
        .when('isCanadianBank', {
          is: ( isCanadianBank ) => isCanadianBank === "No",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      internationalBankRoutingNumber: Yup.string()
        .when(['isCanadianBank', 'internationalBankIban'], {
          is: ( isCanadianBank, internationalBankIban ) => {
            if ( isCanadianBank === "Yes" ) return false;
            return !internationalBankIban || internationalBankIban.length === 0;
          },
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      internationalBankAccountNumber: Yup.string()
        .when(['isCanadianBank', 'internationalBankIban'], {
          is: ( isCanadianBank, internationalBankIban ) => {
            if ( isCanadianBank === "Yes" ) return false;
            return !internationalBankIban || internationalBankIban.length === 0;
          },
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      internationalBankSwiftCode: Yup.string()
        .when(['isCanadianBank', 'internationalBankIban'], {
          is: ( isCanadianBank, internationalBankIban ) => {
            if ( isCanadianBank === "Yes" ) return false;
            return !internationalBankIban || internationalBankIban.length === 0;
          },
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      internationalBankIban: Yup.string().optional(),

      // Transactions Schema
      transactions: Yup.array().of(
        Yup.object().shape({
          date: Yup.string()
            .nullable(true)
            .required('Required'),
          transactionType: Yup.string()
            .required('Required'),
          transactionManner: Yup.string()
            .required('Required'),
          numShares: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          perSharePrice: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          totalAmount: Yup.string(),
          commission: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
        }),
      ),

      // Distribution Calculation Primary Market Schema
      /** 1   */
      pmAcquiredShares: Yup.string()
        .required('Required'),
      /** 2   */
      pmNumberSharesAcquired: Yup.number()
        .when('pmAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 3   */
      pmWeightedAveragePricePaidFeb17Mar2inCAD: Yup.number()
        .when('pmAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 4   */
      pmStillHoldingAllShares: Yup.string()
        .when('pmAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      /** 5   */
      pmMarketSharesDisposed: Yup.array().of(
        Yup.string()
      )
        .when('pmStillHoldingAllShares', {
          is: ( val ) => val === "No",
          then: Yup.array().of(
            Yup.string()
          )
            .required('Required'),
          otherwise: Yup.array().of(
            Yup.string()
          )
            .optional()
        }),
      /** 6   */
      pmSharesDisposedFeb17Mar2: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 7   */
      pmWeightedAveragePriceReceivedPMFeb17Mar2inUSD: Yup.number()
        .when('pmSharesDisposedFeb17Mar2', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 8   */
      pmLossFeb17Mar2: Yup.string(),
      /** 9   */
      pmSharesDisposedMar3Mar11: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 10A */
      pmWeightedAveragePriceReceivedMar3Mar11inUSD: Yup.number()
        .when('pmSharesDisposedMar3Mar11', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 11  */
      pmLossMar3Mar11: Yup.string(),
      /** 12A */
      pmSharesDisposedApr23May6: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("CSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 12B */
      pmSharesDisposedApr27May11: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("FSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 12C */
      pmSharesDisposedMar12Mar25: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          othewise: Yup.number().optional()
        }),
      /** 13A */
      pmWeightedAveragePriceReceivedApr23May6inCAD: Yup.number()
        .when('pmSharesDisposedApr23May6', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 13B */
      pmWeightedAveragePriceReceivedApr27May11inEUR: Yup.number()
        .when('pmSharesDisposedApr27May11', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 13C */
      pmWeightedAveragePriceReceivedMar12Mar25inUSD: Yup.number()
        .when('pmSharesDisposedMar12Mar25', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 14A */
      pmLossApr23May6: Yup.string(),
      /** 14B */
      pmLossApr27May11: Yup.string(),
      /** 14C */
      pmLossMar12Mar25: Yup.string(),
      /** 15A */
      pmSharesDisposedAfterMay6: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("CSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 15B */
      pmSharesDisposedAfterMay11: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("FSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 15C */
      pmSharesDisposedAfterMar25: Yup.number()
        .when('pmMarketSharesDisposed', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 16A */
      pmWeightedAveragePriceReceivedAfterMay6inCAD: Yup.number()
        .when('pmSharesDisposedAfterMay6', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherise: Yup.number().optional()
        }),
      /** 16B */
      pmWeightedAveragePriceReceivedAfterMay11inEUR: Yup.number()
        .when('pmSharesDisposedAfterMay11', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 16C */
      pmWeightedAveragePriceReceivedAfterMar25inUSD: Yup.number()
        .when('pmSharesDisposedAfterMar25', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 17A */
      pmLossAfterMay6: Yup.string(),
      /** 17B */
      pmLossAfterMay11: Yup.string(),
      /** 17C */
      pmLossAfterMar25: Yup.string(),
      /** 18  */
      pmSharesStillHolding: Yup.number()
        .when('pmStillHoldingAllShares', {
          is: ( val ) => val === "No",
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 19  */
      pmLossStillHolding: Yup.string(),
      /** 20  */
      pmMaxEntitlement: Yup.string(),
      /** 21  */
      pmAdjustedDamages: Yup.string(),

      // Distribution Calculation Secondary Market Schema
      /** 22  */
      smAcquiredShares: Yup.string()
        .required('Required'),
      /** 23  */
      smMarketSharesAcquired: Yup.array().of(
        Yup.string()
      )
        .when('smAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.array().of(
            Yup.string()
          )
            .required('Required'),
          otherwise: Yup.array().of(
            Yup.string()
          )
            .optional()
        }),
      /** 24  */
      smNumberSharesAcquired: Yup.number()
        .when('smAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        })
        .test(
          'smNumberSharesAcquired',
          ( d ) => `The number of shares purchased on the CSE, FSE, and OTC must equal the total number of shares acquired during the Class Period, in this case: ${ d.value }`,
          ( val, ctx ) => val === ctx.parent.smNumberSharesAcquiredCSE + ctx.parent.smNumberSharesAcquiredFSE + ctx.parent.smNumberSharesAcquiredOTC
        ),
      /** 24A */
      smNumberSharesAcquiredCSE: Yup.number()
        .when('smAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 24B */
      smNumberSharesAcquiredFSE: Yup.number()
        .when('smAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 24C */
      smNumberSharesAcquiredOTC: Yup.number()
        .when('smAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.number()
            .min(0, 'Must be greater than 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 25A */
      smWeightedAveragePricePaidCSEinCAD: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("CSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 25B */
      smWeightedAveragePricePaidFSEinEUR: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("FSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 25C */
      smWeightedAveragePricePaidOTCinUSD: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 26  */
      smStillHoldingAllShares: Yup.string()
        .when('smAcquiredShares', {
          is: ( val ) => val === "Yes",
          then: Yup.string()
            .required('Required'),
          otherwise: Yup.string().optional()
        }),
      /** 27  */
      smMarketSharesDisposed: Yup.array().of(
        Yup.string()
      )
        .when('smStillHoldingAllShares', {
          is: ( val ) => val === "No",
          then: Yup.array().of(
            Yup.string()
          )
            .required('Required'),
          otherwise: Yup.array().of(
            Yup.string()
          )
            .optional()
        }),
      /** 28  */
      smSharesDisposedFeb17Mar2: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 29A */
      smWeightedAveragePriceReceivedFeb17Mar2inUSD: Yup.number()
        .when('smSharesDisposedFeb17Mar2', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 31  */
      smSharesDisposedMar3Mar11: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 32A */
      smWeightedAveragePriceReceivedMar3Mar11inUSD: Yup.number()
        .when('smSharesDisposedMar3Mar11', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 34A */
      smSharesDisposedApr23May6: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("CSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required')
        }),
      /** 34B */
      smSharesDisposedApr27May11: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("FSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 34C */
      smSharesDisposedMar12Mar25: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 35A */
      smWeightedAveragePriceReceivedApr23May6inCAD: Yup.number()
        .when('smSharesDisposedApr23May6', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 35B */
      smWeightedAveragePriceReceivedApr27May11inEUR: Yup.number()
        .when('smSharesDisposedApr27May11', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 35C */
      smWeightedAveragePriceReceivedMar12Mar25inUSD: Yup.number()
        .when('smSharesDisposedMar12Mar25', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required')
        }),
      /** 37A */
      smSharesDisposedAfterMay6: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("CSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 37B */
      smSharesDisposedAfterMay11: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("FSE"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 37C */
      smSharesDisposedAfterMar25: Yup.number()
        .when('smMarketSharesAcquired', {
          is: ( val ) => val.includes("OTC"),
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 38A */
      smWeightedAveragePriceReceivedAfterMay6inCAD: Yup.number()
        .when('smSharesDisposedAfterMay6', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 38B */
      smWeightedAveragePriceReceivedAfterMay11inEUR: Yup.number()
        .when('smSharesDisposedAfterMay11', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required'),
          otherwise: Yup.number().optional()
        }),
      /** 38C */
      smWeightedAveragePriceReceivedAfterMar25inUSD: Yup.number()
        .when('smSharesDisposedAfterMar25', {
          is: ( val ) => val > 0,
          then: Yup.number()
            .min(0, 'Must be greater than or equal to 0.')
            .required('Required')
        }),
      /** 40  */
      smSharesStillHolding: Yup.number()
        .when('smStillHoldingAllShares', {
          is: ( val ) => val === "No",
          then: Yup.number(),
          otherwise: Yup.number().optional()
        }),
      /** 42  */
      smMaxEntitlement: Yup.string(),
      /** 43  */
      smAdjustedDamages: Yup.string(),

      // Total Compensable Loss Schema
      /** 44  */
      compensableLoss: Yup.string(),

      // Confirmation Schema
      dataVerifiable: Yup.boolean()
        .required('Required')
        .oneOf([true], 'You must certify that the above information is correct.'),
      acceptedLegal: Yup.boolean()
        .required('Required')
        .oneOf([true], 'You must accept the Terms of Use and Privacy Policy.')
    }
  );

  const handleSubmit = async ( e, { resetForm, setStatus } ) => {
    const fullName = e.name;
    const address = `${ e.address } ${ e.secAddress }, ${ e.city }, ${ e.province }, ${ e.country }, ${ e.postalCode }`;
    const homePhone = e.homePhone;
    const workPhone = e.workPhone;
    const email = e.email;
    const registration = e.registration;

    const claimantBehalf = e.claimantBehalf;
    const jointAccount = e.jointAccount;
    const authorityClaimantBehalf = e.authorityClaimantBehalf;
    const claimantNames = e.claimantNames.map( ( claimant ) => claimant.claimantName ).toString();
    const accountNumbers = e.accountNumbers.map( ( account ) => account.accountNumber ).toString();
    const depositAccountName = e.depositAccountName;
    const bankLocation = e.isCanadianBank === "Yes" ? "Canada" : "International";
    const bankInformation = e.isCanadianBank === "Yes" ? `Institution Number: ${ e.canadianBankInstitutionNumber }\nTransit/Branch Number: ${ e.canadianBankBranchNumber }\nAccount Number: ${ e.canadianBankAccountNumber }` : `Bank Name: ${ e.internationalBankBankName }\nBank Address: ${ e.internationalBankBankAddress }\nRouting Number: ${ e.internationalBankRoutingNumber }\nAccount Number: ${ e.internationalBankAccountNumber }\nSwift Code: ${ e.internationalBankSwiftCode }\nIBAN: ${ e.internationalBankIban }`;

    // Transactions
    const transactions = e.transactions.map( ( transaction, _index ) => `
      Transaction ${ _index + 1 }:
      Date: ${ transaction.date }
      Type: ${ transaction.transactionType }
      Manner: ${ transaction.transactionManner }
      Number of Shares: ${ transaction.numShares.toString() }
      Per-Share Price: ${ parseFloat( transaction.perSharePrice ).toFixed( 2 ) }
      Commission Charged: ${ transaction.commission }
      Total Amount (without commission): ${ parseFloat( transaction.totalAmount ).toFixed( 2 ) }
    `).toString();
    
    // Primary Market
    const pmAcquiredShares = e.pmAcquiredShares;
    const pmNumberSharesAcquired = e.pmNumberSharesAcquired.toString();
    const pmWeightedAveragePricePaidFeb17Mar2inCAD = parseFloat( e.pmWeightedAveragePricePaidFeb17Mar2inCAD ).toFixed( 2 );
    const pmStillHoldingAllShares = e.pmStillHoldingAllShares;
    const pmMarketSharesDisposed = e.pmMarketSharesDisposed.toString();

    const pmSharesDisposedFeb17Mar2 = e.pmSharesDisposedFeb17Mar2.toString();
    const pmWeightedAveragePriceReceivedFeb17Mar2inCAD = parseFloat( convertUSDtoCAD( e.pmWeightedAveragePriceReceivedFeb17Mar2inUSD ) ).toFixed( 2 );
    const pmSharesDisposedMar3Mar11 = e.pmSharesDisposedMar3Mar11.toString();
    const pmWeightedAveragePriceReceivedMar3Mar11inCAD = parseFloat( convertUSDtoCAD( e.pmWeightedAveragePriceReceivedMar3Mar11inUSD ) ).toFixed( 2 );
    const pmSharesDisposedMar12Mar25 = e.pmSharesDisposedMar12Mar25.toString();
    const pmWeightedAveragePriceReceivedMar12Mar25inCAD = parseFloat( convertUSDtoCAD( e.pmWeightedAveragePriceReceivedMar12Mar25inUSD ) ).toFixed( 2 );
    const pmSharesDisposedAfterMar25 = e.pmSharesDisposedAfterMar25.toString();
    const pmWeightedAveragePriceReceivedAfterMar25inCAD = parseFloat( convertUSDtoCAD( e.pmWeightedAveragePriceReceivedAfterMar25inUSD ) ).toFixed( 2 );

    const pmSharesDisposedApr23May6 = e.pmSharesDisposedApr23May6.toString();
    const pmWeightedAveragePriceReceivedApr23May6inCAD = parseFloat( e.pmWeightedAveragePriceReceivedApr23May6inCAD ).toFixed( 2 );
    const pmSharesDisposedAfterMay6 = e.pmSharesDisposedAfterMay6.toString();
    const pmWeightedAveragePriceReceivedAfterMay6inCAD = parseFloat( e.pmWeightedAveragePriceReceivedAfterMay6inCAD ).toFixed( 2 );

    const pmSharesDisposedApr27May11 = e.pmSharesDisposedApr27May11.toString();
    const pmWeightedAveragePriceReceivedApr27May11inCAD = parseFloat( convertEURtoCAD( e.pmWeightedAveragePriceReceivedApr27May11inEUR ) ).toFixed( 2 );
    const pmSharesDisposedAfterMay11 = e.pmSharesDisposedAfterMay11.toString();
    const pmWeightedAveragePriceReceivedAfterMay11inCAD = parseFloat( convertEURtoCAD( e.pmWeightedAveragePriceReceivedAfterMay11inEUR ) ).toFixed( 2 );

    const pmSharesStillHolding = e.pmSharesStillHolding.toString();
    const pmMaxEntitlement = e.pmMaxEntitlement;
    const pmAdjustedDamages = e.pmAdjustedDamages;

    // Secondary Market
    const smAcquiredShares = e.smAcquiredShares;
    const smMarketSharesAcquired = e.smMarketSharesAcquired.toString();
    const smNumberSharesAcquired = e.smNumberSharesAcquired.toString();
    const smNumberSharesAcquiredCSE = e.smNumberSharesAcquiredCSE.toString();
    const smNumberSharesAcquiredFSE = e.smNumberSharesAcquiredFSE.toString();
    const smNumberSharesAcquiredOTC = e.smNumberSharesAcquiredOTC.toString();
    const smWeightedAveragePricePaidCSEinCAD = parseFloat( e.smWeightedAveragePricePaidCSEinCAD ).toFixed( 2 );
    const smWeightedAveragePricePaidFSEinCAD = parseFloat( convertEURtoCAD( e.smWeightedAveragePricePaidFSEinEUR ) ).toFixed( 2 );
    const smWeightedAveragePricePaidOTCinCAD = parseFloat( convertUSDtoCAD( e.smWeightedAveragePricePaidOTCinUSD ) ).toFixed( 2 );
    const smStillHoldingAllShares = e.smStillHoldingAllShares;
    const smMarketSharesDisposed = e.smMarketSharesDisposed.toString();

    const smSharesDisposedFeb17Mar2 = e.smSharesDisposedFeb17Mar2.toString();
    const smWeightedAveragePriceReceivedFeb17Mar2inCAD = parseFloat( convertUSDtoCAD( e.smWeightedAveragePriceReceivedFeb17Mar2inUSD ) ).toFixed( 2 );
    const smSharesDisposedMar3Mar11 = e.smSharesDisposedMar3Mar11.toString();
    const smWeightedAveragePriceReceivedMar3Mar11inCAD = parseFloat( convertUSDtoCAD( e.smWeightedAveragePriceReceivedMar3Mar11inUSD ) ).toFixed( 2 );
    const smSharesDisposedMar12Mar25 = e.smSharesDisposedMar12Mar25.toString();
    const smWeightedAveragePriceReceivedMar12Mar25inCAD = parseFloat( convertUSDtoCAD( e.smWeightedAveragePriceReceivedMar12Mar25inUSD ) ).toFixed( 2 );
    const smSharesDisposedAfterMar25 = e.smSharesDisposedAfterMar25.toString();
    const smWeightedAveragePriceReceivedAfterMar25inCAD = parseFloat( convertUSDtoCAD( e.smWeightedAveragePriceReceivedAfterMar25inUSD ) ).toFixed( 2 );

    const smSharesDisposedApr23May6 = e.smSharesDisposedApr23May6.toString();
    const smWeightedAveragePriceReceivedApr23May6inCAD = parseFloat( e.smWeightedAveragePriceReceivedApr23May6inCAD ).toFixed( 2 );
    const smSharesDisposedAfterMay6 = e.smSharesDisposedAfterMay6.toString();
    const smWeightedAveragePriceReceivedAfterMay6inCAD = parseFloat( e.smWeightedAveragePriceReceivedAfterMay6inCAD ).toFixed( 2 );

    const smSharesDisposedApr27May11 = e.smSharesDisposedApr27May11.toString();
    const smWeightedAveragePriceReceivedApr27May11inCAD = parseFloat( convertEURtoCAD( e.smWeightedAveragePriceReceivedApr27May11inEUR ) ).toFixed( 2 );
    const smSharesDisposedAfterMay11 = e.smSharesDisposedAfterMay11.toString();
    const smWeightedAveragePriceReceivedAfterMay11inCAD = parseFloat( convertEURtoCAD( e.smWeightedAveragePriceReceivedAfterMay11inEUR ) ).toFixed( 2 );

    const smSharesStillHoldingCSE = e.smSharesStillHoldingCSE.toString();
    const smSharesStillHoldingFSE = e.smSharesStillHoldingFSE.toString();
    const smSharesStillHoldingOTC = e.smSharesStillHoldingOTC.toString();
    const smMaxEntitlement = e.smMaxEntitlement;
    const smAdjustedDamages = e.smAdjustedDamages;

    // Total Loss
    const compensableLoss = e.compensableLoss;

    const subject = `[KND Braxia Form] ${ fullName } - ${ email }`;

    const response = await fetch( twilioMailFunctionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      body: new URLSearchParams({
        subject,

        fullName,
        address,
        homePhone,
        workPhone,
        email,
        registration,

        claimantBehalf,
        jointAccount,
        authorityClaimantBehalf,
        claimantNames,
        accountNumbers,
        depositAccountName,
        bankLocation,
        bankInformation,

        transactions,

        pmAcquiredShares,
        pmNumberSharesAcquired,
        pmWeightedAveragePricePaidFeb17Mar2inCAD,
        pmStillHoldingAllShares,
        pmMarketSharesDisposed,
        pmSharesDisposedFeb17Mar2,
        pmWeightedAveragePriceReceivedFeb17Mar2inCAD,
        pmSharesDisposedMar3Mar11,
        pmWeightedAveragePriceReceivedMar3Mar11inCAD,
        pmSharesDisposedMar12Mar25,
        pmWeightedAveragePriceReceivedMar12Mar25inCAD,
        pmSharesDisposedAfterMar25,
        pmWeightedAveragePriceReceivedAfterMar25inCAD,
        pmSharesDisposedApr23May6,
        pmWeightedAveragePriceReceivedApr23May6inCAD,
        pmSharesDisposedAfterMay6,
        pmWeightedAveragePriceReceivedAfterMay6inCAD,
        pmSharesDisposedApr27May11,
        pmWeightedAveragePriceReceivedApr27May11inCAD,
        pmSharesDisposedAfterMay11,
        pmWeightedAveragePriceReceivedAfterMay11inCAD,
        pmSharesStillHolding,
        pmMaxEntitlement,
        pmAdjustedDamages,

        smAcquiredShares,
        smMarketSharesAcquired,
        smNumberSharesAcquired,
        smNumberSharesAcquiredCSE,
        smNumberSharesAcquiredFSE,
        smNumberSharesAcquiredOTC,
        smWeightedAveragePricePaidCSEinCAD,
        smWeightedAveragePricePaidFSEinCAD,
        smWeightedAveragePricePaidOTCinCAD,
        smStillHoldingAllShares,
        smMarketSharesDisposed,
        smSharesDisposedFeb17Mar2,
        smWeightedAveragePriceReceivedFeb17Mar2inCAD,
        smSharesDisposedMar3Mar11,
        smWeightedAveragePriceReceivedMar3Mar11inCAD,
        smSharesDisposedMar12Mar25,
        smWeightedAveragePriceReceivedMar12Mar25inCAD,
        smSharesDisposedAfterMar25,
        smWeightedAveragePriceReceivedAfterMar25inCAD,
        smSharesDisposedApr23May6,
        smWeightedAveragePriceReceivedApr23May6inCAD,
        smSharesDisposedAfterMay6,
        smWeightedAveragePriceReceivedAfterMay6inCAD,
        smSharesDisposedApr27May11,
        smWeightedAveragePriceReceivedApr27May11inCAD,
        smSharesDisposedAfterMay11,
        smWeightedAveragePriceReceivedAfterMay11inCAD,
        smSharesStillHoldingCSE,
        smSharesStillHoldingFSE,
        smSharesStillHoldingOTC,
        smMaxEntitlement,
        smAdjustedDamages,

        compensableLoss
      }),
    });

    if ( response.status === 200 ) {
      setStatus({
        success: true
      });
    } else {
      setStatus({
        success: false
      });
    }
  };

  /*
  const handleSubmit = async ( e, { resetForm, setStatus } ) => {
    const fieldsSettlementInfo = {
      fields: {
        "Name": e.name,
        "Address": `${ e.address }, ${ e.secAddress }\n${ e.city }\n${ e.province }\n${ e.country }\n${ e.postalCode }`,
        "Home/Cell Phone": e.homePhone,
        "Work Phone": e.workPhone,
        "Email": e.email,
        "Registration": e.registration,
        
        "Claimant Behalf": e.claimantBehalf,
        "Joint Account": e.jointAccount,
        "Has Claimant Authority": e.authorityClaimantBehalf,
        "Claimant Names": e.claimantNames.map( ( claimant ) => claimant.claimantName ).toString(),
        "Account Numbers": e.accountNumbers.map( ( account ) => account.accountNumber ).toString(),
        "Deposit Account Name": e.depositAccountName,
        "Bank Location": e.isCanadianBank === "Yes" ? "Canada" : "International",
        "Bank Information": e.isCanadianBank === "Yes" ? `Institution Number: ${ e.canadianBankInstitutionNumber }\nTransit/Branch Number: ${ e.canadianBankBranchNumber }\nAccount Number: ${ e.canadianBankAccountNumber }` : `Bank Name: ${ e.internationalBankBankName }\nBank Address: ${ e.internationalBankBankAddress }\nRouting Number: ${ e.internationalBankRoutingNumber }\nAccount Number: ${ e.internationalBankAccountNumber }\nSwift Code: ${ e.internationalBankSwiftCode }\nIBAN: ${ e.internationalBankIban }`,
        
        "Acquired Shares PM": e.pmAcquiredShares,
        "Number of Shares Acquired PM": e.pmNumberSharesAcquired,
        "Weighted Average Price Paid PM (CAD)": e.pmWeightedAveragePricePaidFeb17Mar2inCAD,
        "Still Holding All Shares PM": e.pmStillHoldingAllShares,
        "Market Shares Disposed PM": e.pmMarketSharesDisposed.toString(),
        "Shares Disposed PM - Feb17/Mar2": e.pmSharesDisposedFeb17Mar2,
        "Weighted Average Price Received PM - Feb17/Mar2 (CAD)": convertUSDtoCAD( e.pmWeightedAveragePriceReceivedFeb17Mar2inUSD ),
        "Shares Disposed PM - Mar3/Mar11": e.pmSharesDisposedMar3Mar11,
        "Weighted Average Price Received PM - Mar3/Mar11 (CAD)": convertUSDtoCAD( e.pmWeightedAveragePriceReceivedMar3Mar11inUSD ),
        "Shares Disposed PM - Apr23/May6": e.pmSharesDisposedApr23May6,
        "Weighted Average Price Received PM - Apr23/May6 (CAD)": e.pmWeightedAveragePriceReceivedApr23May6inCAD,
        "Shares Disposed PM - Apr27/May11": e.pmSharesDisposedApr27May11,
        "Weighted Average Price Received PM - Apr27/May11 (CAD)": convertEURtoCAD( e.pmWeightedAveragePriceReceivedApr27May11inEUR ),
        "Shares Disposed PM - Mar12/Mar25": e.pmSharesDisposedMar12Mar25,
        "Weighted Average Price Received PM - Mar12/Mar25 (CAD)": convertUSDtoCAD( e.pmWeightedAveragePriceReceivedMar12Mar25inUSD ),
        "Shares Disposed PM - After May6": e.pmSharesDisposedAfterMay6,
        "Weighted Average Price Received PM - After May6 (CAD)": e.pmWeightedAveragePriceReceivedAfterMay6inCAD,
        "Shares Disposed PM - After May11": e.pmSharesDisposedAfterMay11,
        "Weighted Average Price Received PM - After May11 (CAD)": convertEURtoCAD( e.pmWeightedAveragePriceReceivedAfterMay11inEUR ),
        "Shares Disposed PM - After Mar25": e.pmSharesDisposedAfterMar25,
        "Weighted Average Price Received PM - After Mar25 (CAD)": convertUSDtoCAD( e.pmWeightedAveragePriceReceivedAfterMar25inUSD ),
        "Shares Still Holding PM": e.pmSharesStillHolding,
        "Maximum Entitlement PM": Number( e.pmMaxEntitlement ),
        "Adjusted Damages PM": Number( e.pmAdjustedDamages ),

        "Acquired Shares SM": e.smAcquiredShares,
        "Market Shares Acquired SM": e.smMarketSharesAcquired.toString(),
        "Number of Shares Acquired SM": e.smNumberSharesAcquired,
        "Number of Shares Acquired SM - CSE": e.smNumberSharesAcquiredCSE,
        "Number of Shares Acquired SM - FSE": e.smNumberSharesAcquiredFSE,
        "Number of Shares Acquired SM - OTC": e.smNumberSharesAcquiredOTC,
        "Weighted Average Price Paid SM - CSE (CAD)": e.smWeightedAveragePricePaidCSEinCAD,
        "Weighted Average Price Paid SM - FSE (CAD)": convertEURtoCAD( e.smWeightedAveragePricePaidFSEinEUR ),
        "Weighted Average Price Paid SM - OTC (CAD)": convertUSDtoCAD( e.smWeightedAveragePricePaidOTCinUSD ),
        "Still Holding All Shares SM": e.smStillHoldingAllShares,
        "Market Shares Disposed SM": e.smMarketSharesDisposed.toString(),
        "Shares Disposed SM - Feb17/Mar2": e.smSharesDisposedFeb17Mar2,
        "Weighted Average Price Received SM - Feb17/Mar2 (CAD)": convertUSDtoCAD( e.smWeightedAveragePriceReceivedFeb17Mar2inUSD ),
        "Shares Disposed SM - Mar3/Mar11": e.smSharesDisposedMar3Mar11,
        "Weighted Average Price Received SM - Mar3/Mar11 (CAD)": convertUSDtoCAD( e.smWeightedAveragePriceReceivedMar3Mar11inUSD ),
        "Shares Disposed SM - Apr23/May6": e.smSharesDisposedApr23May6,
        "Weighted Average Price Received SM - Apr23/May6 (CAD)": e.smWeightedAveragePriceReceivedApr23May6inCAD,
        "Shares Disposed SM - Apr27/May11": e.smSharesDisposedApr27May11,
        "Weighted Average Price Received SM - Apr27/May11 (CAD)": convertEURtoCAD( e.smWeightedAveragePriceReceivedApr27May11inEUR ),
        "Shares Disposed SM - Mar12/Mar25": e.smSharesDisposedMar12Mar25,
        "Weighted Average Price Received SM - Mar12/Mar25 (CAD)": convertUSDtoCAD( e.smWeightedAveragePriceReceivedMar12Mar25inUSD ),
        "Shares Disposed SM - After May6": e.smSharesDisposedAfterMay6,
        "Weighted Average Price Received SM - After May6 (CAD)": e.smWeightedAveragePriceReceivedAfterMay6inCAD,
        "Shares Disposed SM - After May11": e.smSharesDisposedAfterMay11,
        "Weighted Average Price Received SM - After May11 (CAD)": convertEURtoCAD( e.smWeightedAveragePriceReceivedAfterMay11inEUR ),
        "Shares Disposed SM - After Mar25": e.smSharesDisposedAfterMar25,
        "Weighted Average Price Received SM - After Mar25 (CAD)": convertUSDtoCAD( e.smWeightedAveragePriceReceivedAfterMar25inUSD ),
        "Shares Still Holding SM - CSE": e.smSharesStillHoldingCSE,
        "Shares Still Holding SM - FSE": e.smSharesStillHoldingFSE,
        "Shares Still Holding SM - OTC": e.smSharesStillHoldingOTC,
        "Maximum Entitlement SM": Number( e.smMaxEntitlement ),
        "Adjusted Damages SM": Number( e.smAdjustedDamages ),
        
        "Total Compensable Loss": Number( e.compensableLoss ),
      }
    };
    const fieldsTransactions = e.transactions.map( ( transaction, _index ) => {
      return {
        fields: {
          "Name": e.name,
          "Transaction Date": transaction.date,
          "Transaction Type": transaction.transactionType,
          "Transaction Manner": transaction.transactionManner,
          "Number of Shares": transaction.numShares,
          "Per-Share Price": transaction.perSharePrice,
          "Commission Charged": transaction.commission,
          "Total Amount (without Commission)": Number( transaction.totalAmount ),
        }
      }
    });

    for ( let transaction of fieldsTransactions ) {
      await fetch( endpointTransactions, {
        method: "POST",
        headers: {
          "Authorization": "Bearer keyLdsmuEAoZBebaQ",
          "Content-Type": "application/json",
        },
        body: JSON.stringify( transaction )
      })
        .then()
        .catch( () => setStatus({ success: false }) );
    }

    await fetch( endpointSettlementInfo, {
      method: "POST",
      headers: {
        "Authorization": "Bearer keyLdsmuEAoZBebaQ",
        "Content-Type": "application/json",
      },
      body: JSON.stringify( fieldsSettlementInfo )
    })
      .then( () => setStatus({ success: true }) )
      .catch( () => setStatus({ success: false }) );

    setStatus({ success: false });
  };
  */

  /*** Options ***/

  // Identification
  const registrationOptions = [
    { label: "Individual", value: "Individual" },
    { label: "Corporation/Other", value: "Corporation/Other" },
  ];

  // Identification of Claimant(s) and Relevant Accounts
  const claimantBehalfOptions = [
    { label: "On my own behalf", value: "On my own behalf" },
    { label: "On behalf of someone else", value: "On behalf of someone else" },
  ];

  const jointAccountOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const authorityClaimantBehalfOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const isCanadianBankOptions = [
    { label: "Yes, it's a Canadian bank account", value: "Yes" },
    { label: "No, it's an international bank account", value: "No" },
  ];

  // Transactions
  const transactionTypeOptions = [
    { label: "Purchase", value: "Purchase" },
    { label: "Sale", value: "Sale" },
  ];
  
  const transactionMannerOptions = [
    { label: "Private Placement", value: "Private Placement" },
    { label: "CSE", value: "CSE" },
    { label: "FSE", value: "FSE" },
    { label: "OTC", value: "OTC" },
  ];

  // Calculation of Distribution, Maximum Entitlement, and Adjusted Damages
  // Primary Market
  const pmAcquiredSharesOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const pmMarketSharesDisposedOptions = [
    { label: "CSE", value: "CSE" },
    { label: "FSE", value: "FSE" },
    { label: "OTC", value: "OTC" },
  ];

  const pmStillHoldingAllSharesOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  // Secondary Market
  const smAcquiredSharesOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const smMarketSharesAcquiredOptions = [
    { label: "CSE", value: "CSE" },
    { label: "FSE", value: "FSE" },
    { label: "OTC", value: "OTC" },
  ];

  const smStillHoldingAllSharesOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const smMarketSharesDisposedOptions = [
    { label: "CSE", value: "CSE" },
    { label: "FSE", value: "FSE" },
    { label: "OTC", value: "OTC" },
  ];

  /*** Functions ***/
  const convertUSDtoCAD = ( val ) => {
    return val * USDtoCAD;
  };

  const convertEURtoCAD = ( val ) => {
    return val * EURtoCAD;
  };

  // Primary Market
  const pmCalcLossFeb17Mar2 = () => {
    return parseFloat( ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - convertUSDtoCAD( ref.current.values.pmWeightedAveragePriceReceivedFeb17Mar2inUSD ) ) * ref.current.values.pmSharesDisposedFeb17Mar2 ).toFixed( 2 );
  };

  const pmCalcLossMar3Mar11 = () => {
    let val1 = ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - convertUSDtoCAD( ref.current.values.pmWeightedAveragePriceReceivedMar3Mar11inUSD ) ) * ref.current.values.pmSharesDisposedMar3Mar11;
    let val2 = 0.08323 * ref.current.values.pmSharesDisposedMar3Mar11;

    return val1 > val2 ? parseFloat( val2 ).toFixed( 2 ) : parseFloat( val1 ).toFixed( 2 );
  };

  const pmCalcLossApr23May6 = () => {
    return parseFloat( ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - ref.current.values.pmWeightedAveragePriceReceivedApr23May6inCAD ) * ref.current.values.pmSharesDisposedApr23May6 ).toFixed( 2 );
  };

  const pmCalcLossApr27May11 = () => {
    return parseFloat( ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - convertEURtoCAD( ref.current.values.pmWeightedAveragePriceReceivedApr27May11inEUR ) ) * ref.current.values.pmSharesDisposedApr27May11 ).toFixed( 2 );
  };

  const pmCalcLossMar12Mar25 = () => {
    return parseFloat( ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - convertUSDtoCAD( ref.current.values.pmWeightedAveragePriceReceivedMar12Mar25inUSD ) ) * ref.current.values.pmSharesDisposedMar12Mar25 ).toFixed( 2 );
  };

  const pmCalcLossAfterMay6 = () => {
    let val1 = ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - ref.current.values.pmWeightedAveragePriceReceivedAfterMay6inCAD ) * ref.current.values.pmSharesDisposedAfterMay6;
    let val2 = 0.20 * ref.current.values.pmSharesDisposedAfterMay6;

    return val1 > val2 ? parseFloat( val2 ).toFixed( 2 ) : parseFloat( val1 ).toFixed( 2 );
  };

  const pmCalcLossAfterMay11 = () => {
    let val1 = ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - convertEURtoCAD( ref.current.values.pmWeightedAveragePriceReceivedAfterMay11inEUR ) ) * ref.current.values.pmSharesDisposedAfterMay11;
    let val2 = 0.3061 * ref.current.values.pmSharesDisposedAfterMay11;

    return val1 > val2 ? parseFloat( val2 ).toFixed( 2 ) : parseFloat( val1 ).toFixed( 2 );
  };

  const pmCalcLossAfterMar25 = () => {
    let val1 = ( ref.current.values.pmWeightedAveragePricePaidFeb17Mar2inCAD - convertUSDtoCAD( ref.current.values.pmWeightedAveragePriceReceivedAfterMar25inUSD ) ) * ref.current.values.pmSharesDisposedAfterMar25;
    let val2 = 0.30959 * ref.current.values.pmSharesDisposedAfterMar25;

    return val1 > val2 ? parseFloat( val2 ).toFixed( 2 ) : parseFloat( val1 ).toFixed( 2 );
  };

  const pmCalcLossStillHolding = () => {
    return parseFloat( 0.20 * ref.current.values.pmSharesStillHolding ).toFixed( 2 );
  };

  const pmCalcMaxEntitlement = () => {
    switch ( ref.current.values.pmStillHoldingAllShares ) {
      case 'Yes':
        return parseFloat( ref.current.values.pmNumberSharesAcquired * 0.20 ).toFixed( 2 );
      case 'No':
        return parseFloat( Number( ref.current.values.pmLossFeb17Mar2 ) + Number( ref.current.values.pmLossMar3Mar11 ) + Number( ref.current.values.pmLossApr23May6 ) + Number( ref.current.values.pmLossApr27May11 ) + Number( ref.current.values.pmLossMar12Mar25 ) + Number( ref.current.values.pmLossAfterMay6 ) + Number( ref.current.values.pmLossAfterMay11 ) + Number( ref.current.values.pmLossAfterMar25 ) + Number( ref.current.values.pmLossStillHolding ) ).toFixed( 2 );
      default:
        return '0';
    }
  };

  const pmCalcAdjustedDamages = () => {
    return parseFloat( Number( ref.current.values.pmMaxEntitlement ) * 0.1053 ).toFixed( 2 );
  };

  // Secondary Market
  const smConversionMar3Mar11 = () => {
    let val1 = convertUSDtoCAD( ref.current.values.smWeightedAveragePriceReceivedMar3Mar11inUSD );
    let val2 = 0.76677;

    return val1 > val2 ? val1 : val2;
  };

  const smConversionAfterMay6 = () => {
    let val1 = ref.current.values.smWeightedAveragePriceReceivedAfterMay6inCAD;
    let val2 = 0.65;

    return val1 > val2 ? val1 : val2;
  };

  const smConversionAfterMay11 = () => {
    let val1 = convertEURtoCAD( ref.current.values.smWeightedAveragePriceReceivedAfterMay11inEUR );
    let val2 = 0.5439;

    return val1 > val2 ? val1 : val2;
  };

  const smConversionAfterMar25 = () => {
    let val1 = convertUSDtoCAD( ref.current.values.smWeightedAveragePriceReceivedAfterMar25inUSD );
    let val2 = 0.54051;

    return val1 > val2 ? val1 : val2;
  };

  const smCalcMaxEntitlement = () => {
    let valAcquiredSharesCSE = ref.current.values.smNumberSharesAcquiredCSE * ref.current.values.smWeightedAveragePricePaidCSEinCAD;
    let valAcquiredSharesFSE = ref.current.values.smNumberSharesAcquiredFSE * convertEURtoCAD( ref.current.values.smWeightedAveragePricePaidFSEinEUR );
    let valAcquiredSharesOTC = ref.current.values.smNumberSharesAcquiredOTC * convertUSDtoCAD( ref.current.values.smWeightedAveragePricePaidOTCinUSD );

    let valStillHoldingAllSharesCSE = valAcquiredSharesCSE - ref.current.values.smNumberSharesAcquiredCSE * 0.65;
    let valStillHoldingAllSharesFSE = valAcquiredSharesFSE - ref.current.values.smNumberSharesAcquiredFSE * 0.5439;
    let valStillHoldingAllSharesOTC = valAcquiredSharesOTC - ref.current.values.smNumberSharesAcquiredOTC * 0.54051;
    let valStillHoldingAllShares = valStillHoldingAllSharesCSE + valStillHoldingAllSharesFSE + valStillHoldingAllSharesOTC;

    let valDisposedSharesCSE = ref.current.values.smSharesDisposedApr23May6 * ref.current.values.smWeightedAveragePriceReceivedApr23May6inCAD + ref.current.values.smSharesDisposedAfterMay6 * smConversionAfterMay6();
    let valDisposedSharesFSE = ref.current.values.smSharesDisposedApr27May11 * convertEURtoCAD( ref.current.values.smWeightedAveragePriceReceivedApr27May11inEUR ) + ref.current.values.smSharesDisposedAfterMay11 * smConversionAfterMay11();
    let valDisposedSharesOTC = ref.current.values.smSharesDisposedFeb17Mar2 * convertUSDtoCAD( ref.current.values.smWeightedAveragePriceReceivedFeb17Mar2inUSD ) + ref.current.values.smSharesDisposedMar3Mar11 * smConversionMar3Mar11() + ref.current.values.smSharesDisposedMar12Mar25 * convertUSDtoCAD( ref.current.values.smWeightedAveragePriceReceivedMar12Mar25inUSD ) + ref.current.values.smSharesDisposedAfterMar25 * smConversionAfterMar25();
    let valSharesHolding = ref.current.values.smSharesStillHoldingCSE * 0.65 + ref.current.values.smSharesStillHoldingFSE * 0.5439 + ref.current.values.smSharesStillHoldingOTC * 0.54051;
    let valDisposedShares = valAcquiredSharesCSE + valAcquiredSharesFSE + valAcquiredSharesOTC - valDisposedSharesOTC - valDisposedSharesFSE - valDisposedSharesCSE - valSharesHolding;

    return ref.current.values.smStillHoldingAllShares === "No" ? parseFloat( valDisposedShares ).toFixed( 2 ) : parseFloat( valStillHoldingAllShares ).toFixed( 2 );
  };

  const smCalcAdjustedDamages = () => {
    return parseFloat( Number( ref.current.values.smMaxEntitlement ) * 0.8947 ).toFixed( 2 );
  };

  // Total
  const calcCompensableLoss = () => {
    let compensableLoss = Number( ref.current.values.pmAdjustedDamages ) + Number( ref.current.values.smAdjustedDamages );
    
    return parseFloat( compensableLoss ).toFixed( 2 );
  };

  return (
    <section className="container mx-auto my-16 px-6 lg:px-28 2xl:px-80">
      <Formik
        innerRef={ ref }
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        validateOnChange={ true }
        onSubmit={ handleSubmit }
      >
        { props => (
          <Form>
            <div>
              <div className="flex flex-col space-y-8 text-lg">
                <div className="flex flex-col w-full space-y-4">
                  <h4>
                    IDENTIFICATION OF FILER (CLAIMAINT OR AUTHORIZED REPRESENTATIVE ACTING ON CLAIMANT'S BEHALF)
                  </h4>
                  <FormikControl
                    control="input"
                    label="Name of Person Submitting Claim Form (Claimant or their Authorized Representative)"
                    name="name"
                    type="text"
                    placeholder="Enter your full name"
                  />
                  <FormikControl
                    control="input"
                    label="Street Address"
                    name="address"
                    type="text"
                    placeholder="Enter your street address"
                  />
                  <FormikControl
                    control="input"
                    label="Secondary Street Address"
                    name="secAddress"
                    type="text"
                    placeholder="Apt. #, Suite #, etc."
                  />
                  <FormikControl
                    control="input"
                    label="City"
                    name="city"
                    type="text"
                    placeholder="Enter your city"
                  />
                  <FormikControl
                    control="input"
                    label="Province/State"
                    name="province"
                    type="text"
                    placeholder="Enter your province/state"
                  />
                  <FormikControl
                    control="input"
                    label="Postal Code/Zipcode"
                    name="postalCode"
                    type="text"
                    placeholder="Enter your postal code/zipcode"
                  />
                  <FormikControl
                    control="input"
                    label="Country"
                    name="country"
                    type="text"
                    placeholder="Enter your country"
                  />
                  <FormikControl
                    control="input"
                    label="Work Phone"
                    name="workPhone"
                    type="tel"
                    placeholder="Enter your work phone number"
                  />
                  <FormikControl
                    control="input"
                    label="Home/Cell Phone"
                    name="homePhone"
                    type="tel"
                    placeholder="Enter your home/cell phone number"
                  />
                  <FormikControl
                    control="radioGroup"
                    label="Registration Type"
                    name="registration"
                    options={ registrationOptions }
                  />
                  <FormikControl
                    control="input"
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                  />
                </div>



                <h4>
                  IDENTIFICATION OF CLAIMANT(S) AND RELEVANT ACCOUNTS
                </h4>
                <div className="flex flex-col w-full space-y-4">
                  <FormikControl
                    control="radioGroup"
                    label="Are you filing this Claim Form on your own behalf or on behalf of someone else?"
                    name="claimantBehalf"
                    options={ claimantBehalfOptions }
                  />
                  { props.values.claimantBehalf === "On behalf of someone else" &&
                    <p className="pb-8 text-base">
                      Please submit documentation evidencing your authority to submit a Claim Form on behalf of this person to <a className="underline" href="mailto:braxia@knd.law">braxia@knd.law</a> upon completion of this form.
                    </p>
                  }
                  <FormikControl
                    control="radioGroup"
                    label="Is this account a joint account (i.e., is more than just one claimant as the owner of the account)?"
                    name="jointAccount"
                    options={ jointAccountOptions }
                  />
                  { props.values.jointAccount === "Yes" &&
                    <>
                      <FormikControl
                        control="radioGroup"
                        label="Do you certify have proper authority to submit this Claim Form on behalf of the other owner of the joint account?"
                        name="authorityClaimantBehalf"
                        options={ authorityClaimantBehalfOptions }
                      />
                      <FormikControl
                        control="input"
                        label="How many people/entities are holders of this joint account?"
                        name="numJointAccountHolders"
                        type="number"
                        placeholder="Enter number of joint account holders"
                      />
                    </>
                  }
                  <FieldArray
                    name="claimantNames"
                  >
                    {({ insert, remove, push }) => (
                      <>
                        <div>What is/are the name(s) of the Claimant(s)? This must match the name on the account statements provided as Supporting Documentation.</div>
                        { props.values.claimantNames.length > 0 &&
                          props.values.claimantNames.map( ( claimantName, _index ) => (
                          <>
                            <div
                              key={ _index }
                              className="flex flex-row items-center w-full space-x-4"
                            >
                              <button
                                className="w-10 h-10 bg-blue-dark text-white"
                                onClick={ () => remove( _index ) }
                              >
                                <div className="flex flex-row justify-center items-center">
                                  <span className="flex-inital font-thin">X</span>
                                </div>
                              </button>
                              <FormikControl
                                control="input"
                                label={`Claimant Name ${ _index + 1 }:`}
                                name={`claimantNames.${ _index }.claimantName`}
                                type="text"
                                placeholder="Enter claimant name"
                              />
                            </div>
                          </>
                        ))}
                        <button
                          className="h-10 bg-blue-dark text-white"
                          onClick={ () => push({
                            claimantName: '',
                          })}
                        >
                          <div className="flex flex-row justify-center items-center">
                            <span className="flex-inital font-thin">Add Another Claimant</span>
                          </div>
                        </button>
                      </>
                    )}
                  </FieldArray>
                  <FieldArray
                    name="accountNumbers"
                  >
                    {({ insert, remove, push }) => (
                      <>
                        <div>Please provide all of the account numbers that this Claim Form relates to (all accounts owned by one person or entity that made purchases and sales of Champignon's Shares during the Class Period should be listed).</div>
                        { props.values.accountNumbers.length > 0 &&
                          props.values.accountNumbers.map( ( accountNumber, _index ) => (
                          <>
                            <div
                              key={ _index }
                              className="flex flex-row items-center w-full space-x-4"
                            >
                              <button
                                className="w-10 h-10 bg-blue-dark text-white"
                                onClick={ () => remove( _index ) }
                              >
                                <div className="flex flex-row justify-center items-center">
                                  <span className="flex-inital font-thin">X</span>
                                </div>
                              </button>
                              <FormikControl
                                control="input"
                                label={`Account ${ _index + 1 }:`}
                                name={`accountNumbers.${ _index }.accountNumber`}
                                type="text"
                                placeholder="Enter account number"
                              />
                            </div>
                          </>
                        ))}
                        <button
                          className="h-10 bg-blue-dark text-white"
                          onClick={ () => push({
                            accountNumber: '',
                          })}
                        >
                          <div className="flex flex-row justify-center items-center">
                            <span className="flex-inital font-thin">Add Another Account</span>
                          </div>
                        </button>
                      </>
                    )}
                  </FieldArray>
                  <FormikControl
                    control="input"
                    label="What is the name of the account holder on the account to which you want your Pro Rata Distribution to be deposited into?"
                    name="depositAccountName"
                    type="text"
                    placeholder="Enter account holder name"
                  />
                  <FormikControl
                    control="radioGroup"
                    label="Is the account to which you want your Pro Rata Distribution deposited into a Canadian bank account?"
                    name="isCanadianBank"
                    options={ isCanadianBankOptions }
                  />
                  { props.values.isCanadianBank === "Yes" &&
                    <>
                      <FormikControl
                        control="input"
                        label="Institution number"
                        name="canadianBankInstitutionNumber"
                        type="text"
                        placeholder="Enter institution number"
                      />
                      <FormikControl
                        control="input"
                        label="Transit/Branch number"
                        name="canadianBankBranchNumber"
                        type="text"
                        placeholder="Enter transit/branch number"
                      />
                      <FormikControl
                        control="input"
                        label="Account number"
                        name="canadianBankAccountNumber"
                        type="text"
                        placeholder="Enter account number"
                      />
                    </>
                  }
                  { props.values.isCanadianBank === "No" &&
                    <>
                      <FormikControl
                        control="input"
                        label="Bank name"
                        name="internationalBankBankName"
                        type="text"
                        placeholder="Enter bank name"
                      />
                      <FormikControl 
                        control="input"
                        label="Bank address"
                        name="internationalBankBankAddress"
                        type="text"
                        placeholder="Enter bank address"
                      />
                      <FormikControl
                        control="input"
                        label="Transit/Routing number"
                        name="internationalBankRoutingNumber"
                        type="text"
                        placeholder="Enter routing number"
                      />
                      <FormikControl
                        control="input"
                        label="Account number"
                        name="internationalBankAccountNumber"
                        type="text"
                        placeholder="Enter account number"
                      />
                      <FormikControl
                        control="input"
                        label="SWIFT code"
                        name="internationalBankSwiftCode"
                        type="text"
                        placeholder="Enter SWIFT code"
                      />
                      <FormikControl
                        control="input"
                        label="IBAN"
                        name="internationalBankIban"
                        type="text"
                        placeholder="Enter IBAN"
                      />
                    </>
                  }
                </div>



                <h4>
                  PURCHASES AND SALES OF SHARES
                </h4>
                <div className="flex flex-col w-full space-y-4">
                  <FieldArray
                    name="transactions"
                  >
                    {({ insert, remove, push }) => (
                      <>
                        { props.values.transactions.length > 0 &&
                          props.values.transactions.map( ( transaction, _index ) => (
                          <div
                            key={ _index }
                            className="flex flex-col w-full space-y-4"
                          >
                            <FormikControl
                              control="date"
                              label="Date"
                              nestedBool={ false }
                              name={`transactions.${ _index }.date`}
                            />
                            <FormikControl
                              control="radioGroup"
                              label="Purchase or Sale"
                              name={`transactions.${ _index }.transactionType`}
                              options={ transactionTypeOptions }
                            />
                            <FormikControl
                              control="radioGroup"
                              label="Manner of Transaction"
                              name={`transactions.${ _index }.transactionManner`}
                              options={ transactionMannerOptions }
                            />
                            <FormikControl
                              control="input"
                              label="Number of Shares Purchased/Sold"
                              name={`transactions.${ _index }.numShares`}
                              type="number"
                              placeholder="Enter number of shares"
                            />
                            <FormikControl
                              control="input"
                              label={`Per-share price ${ props.values.transactions[_index].transactionManner === "CSE" ? '(in CAD)' : props.values.transactions[_index].transactionManner === "FSE" ? '(in EUR)' : props.values.transactions[_index].transactionManner === "OTC" ? '(in USD)' : '' }`}
                              name={`transactions.${ _index }.perSharePrice`}
                              type="number"
                              placeholder="Enter per-share price"
                            />
                            
                            <FormikControl
                              control="calculated"
                              label="Total Amount (without Commission)"
                              name={`transactions.${ _index }.totalAmount`}
                              type="text"
                              handleCalc={ parseFloat( props.values.transactions[_index].numShares * props.values.transactions[_index].perSharePrice ).toFixed( 2 ) }
                            />
                            <FormikControl
                              control="input"
                              label={`Commission Charged ${ props.values.transactions[_index].transactionManner === "CSE" ? '(in CAD)' : props.values.transactions[_index].transactionManner === "FSE" ? '(in EUR)' : props.values.transactions[_index].transactionManner === "OTC" ? '(in USD)' : '' }`}
                              name={`transactions.${ _index }.commission`}
                              type="number"
                              placeholder="Enter commission charged"
                            />
                            <button
                              className="h-10 bg-blue-dark text-white"
                              onClick={ () => remove( _index ) }
                            >
                              <div className="flex flex-row justify-center items-center">
                                <span className="flex-inital font-thin">Remove Transaction</span>
                              </div>
                            </button>
                          </div>
                        ))}
                        <button
                          className="h-10 bg-blue-dark text-white"
                          onClick={ () => push({
                            date: new Date().toLocaleDateString(),
                            transactionType: '',
                            transactionManner: '',
                            numShares: 0,
                            perSharePrice: 0,
                            totalAmount: 0,
                            commission: 0,
                          })}
                        >
                          <div className="flex flex-row justify-center items-center">
                            <span className="flex-inital font-thin">Add Transaction</span>
                          </div>
                        </button>
                      </>
                    )}
                  </FieldArray>
                </div>



                <h4>
                  CALCULATION OF THE DISTRIBUTION, MAXIMUM ENTITLEMENT, AND ADJUSTED DAMAGES
                </h4>
                <div className="flex flex-col w-full space-y-4">
                  <FormikControl
                    control="radioGroup"
                    label="Did you acquire Shares pursuant to Champignon's Private Placement which you held until at least February 17, 2021, calculated using LIFO?"
                    name="pmAcquiredShares"
                    options={ pmAcquiredSharesOptions }
                  />
                  { props.values.pmAcquiredShares === "Yes" &&
                    <>
                      <FormikControl
                        control="input"
                        label="How many Shares did you acquire pursuant to Champignon's Private Placement which you held until at least February 17, 2021, calculated using LIFO?"
                        name="pmNumberSharesAcquired"
                        type="number"
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired pursuant to Champignon's Private Placement which you held until at least February 17, 2021, including any commissions paid (in CAD)?"
                        name="pmWeightedAveragePricePaidFeb17Mar2inCAD"
                        type="number"
                        handleCalc={ 0.85 }
                      />
                      <FormikControl
                        control="radioGroup"
                        label="Do you still hold all of your Shares acquired pursuant to Champignon's Private Placement, calculated using LIFO? "
                        name="pmStillHoldingAllShares"
                        options={ pmStillHoldingAllSharesOptions }
                      />
                      { props.values.pmStillHoldingAllShares === "No" &&
                        <>
                          <FormikControl
                            control="checkboxGroup"
                            label="On which exchange/market did you dispose of your Shares acquired pursuant to Champignon's Private Placement which you held until at least February 17, 2021? (check all that apply)"
                            name="pmMarketSharesDisposed"
                            options={ pmMarketSharesDisposedOptions }
                          />
                          { !props.values.pmMarketSharesDisposed.includes("OTC") &&
                            <div className="hidden">
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to the Champignon's Private Placement did you dispose of on the OTC between February 17, 2021 and March 2, 2021 inclusive?"
                                name="pmSharesDisposedFeb17Mar2"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between February 17, 2021 and March 2, 2021 inclusive, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedFeb17Mar2inUSD"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between February 17, 2021 and March 2, 2021 inclusive is (in CAD):"
                                name="pmLossFeb17Mar2"
                                type="text"
                                handleCalc={ '0' }
                              />
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to the Champignon's Private Placement did you dispose of on the OTC between March 3, 2021 and March 11, 2021 inclusive?"
                                name="pmSharesDisposedMar3Mar11"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 3, 2021 and March 11, 2021 inclusive, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedMar3Mar11inUSD"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 3, 2021 and March 11, 2021 inclusive is (in CAD):"
                                name="pmLossMar3Mar11"
                                type="text"
                                handleCalc={ '0' }
                              />
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the OTC between March 12, 2021 and March 25, 2021 inclusive?"
                                name="pmSharesDisposedMar12Mar25"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 12, 2021 and March 25, 2021 inclusive, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedMar12Mar25inUSD"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 12, 2021 and March 25, 2021 inclusive is (in CAD):"
                                name="pmLossMar12Mar25"
                                type="text"
                                handleCalc={ '0' }
                              />
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the OTC after March 25, 2021?"
                                name="pmSharesDisposedAfterMar25"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC after March 25, 2021, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedAfterMar25inUSD"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC after March 25, 2021 is (in CAD):"
                                name="pmLossAfterMar25"
                                type="text"
                                handleCalc={ '0' }
                              />
                            </div>
                          }
                          { props.values.pmMarketSharesDisposed.includes("OTC") &&
                            <>
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to the Champignon's Private Placement did you dispose of on the OTC between February 17, 2021 and March 2, 2021 inclusive?"
                                name="pmSharesDisposedFeb17Mar2"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between February 17, 2021 and March 2, 2021 inclusive, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedFeb17Mar2inUSD"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between February 17, 2021 and March 2, 2021 inclusive is (in CAD):"
                                name="pmLossFeb17Mar2"
                                type="text"
                                handleCalc={ pmCalcLossFeb17Mar2() }
                              />
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to the Champignon's Private Placement did you dispose of on the OTC between March 3, 2021 and March 11, 2021 inclusive?"
                                name="pmSharesDisposedMar3Mar11"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 3, 2021 and March 11, 2021 inclusive, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedMar3Mar11inUSD"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 3, 2021 and March 11, 2021 inclusive is (in CAD):"
                                name="pmLossMar3Mar11"
                                type="text"
                                handleCalc={ pmCalcLossMar3Mar11() }
                              />
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the OTC between March 12, 2021 and March 25, 2021 inclusive?"
                                name="pmSharesDisposedMar12Mar25"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 12, 2021 and March 25, 2021 inclusive, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedMar12Mar25inUSD"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC between March 12, 2021 and March 25, 2021 inclusive is (in CAD):"
                                name="pmLossMar12Mar25"
                                type="text"
                                handleCalc={ pmCalcLossMar12Mar25() }
                              />
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the OTC after March 25, 2021?"
                                name="pmSharesDisposedAfterMar25"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC after March 25, 2021, without deducting commissions (in USD)?"
                                name="pmWeightedAveragePriceReceivedAfterMar25inUSD"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the OTC after March 25, 2021 is (in CAD):"
                                name="pmLossAfterMar25"
                                type="text"
                                handleCalc={ pmCalcLossAfterMar25() }
                              />
                            </>
                          }
                          { !props.values.pmMarketSharesDisposed.includes("CSE") &&
                            <div className="hidden">
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the CSE between April 23, 2021 and May 6, 2021 inclusive?"
                                name="pmSharesDisposedApr23May6"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE between April 23, 2021 and May 6, 2021 inclusive, without deducting commissions (in CAD)?"
                                name="pmWeightedAveragePriceReceivedApr23May6inCAD"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE between March 12, 2021 and May 6, 2021 inclusive is (in CAD):"
                                name="pmLossApr23May6"
                                type="text"
                                handleCalc={ '0' }
                              />
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the CSE after May 6, 2021?"
                                name="pmSharesDisposedAfterMay6"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE after May 6, 2021, without deducting commissions (in CAD)?"
                                name="pmWeightedAveragePriceReceivedAfterMay6inCAD"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE after May 6, 2021 is (in CAD):"
                                name="pmLossAfterMay6"
                                type="text"
                                handleCalc={ '0' }
                              />
                            </div>
                          }
                          { props.values.pmMarketSharesDisposed.includes("CSE") &&
                            <>
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the CSE between April 23, 2021 and May 6, 2021 inclusive?"
                                name="pmSharesDisposedApr23May6"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE between April 23, 2021 and May 6, 2021 inclusive, without deducting commissions (in CAD)?"
                                name="pmWeightedAveragePriceReceivedApr23May6inCAD"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE between March 12, 2021 and May 6, 2021 inclusive is (in CAD):"
                                name="pmLossApr23May6"
                                type="text"
                                handleCalc={ pmCalcLossApr23May6() }
                              />
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the CSE after May 6, 2021?"
                                name="pmSharesDisposedAfterMay6"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE after May 6, 2021, without deducting commissions (in CAD)?"
                                name="pmWeightedAveragePriceReceivedAfterMay6inCAD"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the CSE after May 6, 2021 is (in CAD):"
                                name="pmLossAfterMay6"
                                type="text"
                                handleCalc={ pmCalcLossAfterMay6() }
                              />
                            </>
                          }
                          { !props.values.pmMarketSharesDisposed.includes("FSE") &&
                            <div className="hidden">
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the FSE between April 27, 2021 and May 11, 2021 inclusive?"
                                name="pmSharesDisposedApr27May11"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the FSE between April 27, 2021 and May 11, 2021 inclusive, without deducting commissions (in EUR)?"
                                name="pmWeightedAveragePriceReceivedApr27May11inEUR"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the FSE between March 12, 2021 and May 11, 2021 inclusive is (in CAD):"
                                name="pmLossApr27May11"
                                type="text"
                                handleCalc={ '0' }
                              />
                              <FormikControl
                                control="calculated"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the FSE after May 11, 2021?"
                                name="pmSharesDisposedAfterMay11"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="What was the weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of after May 11, 2021, without deducting commissions (in EUR)?"
                                name="pmWeightedAveragePriceReceivedAfterMay11inEUR"
                                type="number"
                                handleCalc={ 0 }
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of after May 11, 2021, 2021 is (in CAD):"
                                name="pmLossAfterMay11"
                                type="text"
                                handleCalc={ '0' }
                              />
                            </div>
                          }
                          { props.values.pmMarketSharesDisposed.includes("FSE") &&
                            <>
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the FSE between April 27, 2021 and May 11, 2021 inclusive?"
                                name="pmSharesDisposedApr27May11"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the volume weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of on the FSE between April 27, 2021 and May 11, 2021 inclusive, without deducting commissions (in EUR)?"
                                name="pmWeightedAveragePriceReceivedApr27May11inEUR"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of on the FSE between March 12, 2021 and May 11, 2021 inclusive is (in CAD):"
                                name="pmLossApr27May11"
                                type="text"
                                handleCalc={ pmCalcLossApr27May11() }
                              />
                              <FormikControl
                                control="input"
                                label="How many Shares acquired pursuant to Champignon's Private Placement did you dispose of on the FSE after May 11, 2021?"
                                name="pmSharesDisposedAfterMay11"
                                type="number"
                              />
                              <FormikControl
                                control="input"
                                label="What was the weighted average price you received per Share upon disposition of Shares acquired pursuant to Champignon's Private Placement which you disposed of after May 11, 2021, without deducting commissions (in EUR)?"
                                name="pmWeightedAveragePriceReceivedAfterMay11inEUR"
                                type="number"
                              />
                              <FormikControl
                                control="calculated"
                                label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you disposed of after May 11, 2021, 2021 is (in CAD):"
                                name="pmLossAfterMay11"
                                type="text"
                                handleCalc={ pmCalcLossAfterMay11() }
                              />
                            </>
                          }
                          <FormikControl
                            control="input"
                            label="How many Shares acquired pursuant to Champignon's Private Placement are you still holding?"
                            name="pmSharesStillHolding"
                            type="number"
                          />
                          <FormikControl
                            control="calculated"
                            label="Your deemed loss for Shares acquired pursuant to Champignon's Private Placement which you are still holding is (in CAD):"
                            name="pmLossStillHolding"
                            type="text"
                            handleCalc={ pmCalcLossStillHolding() }
                          />
                        </>
                      }
                      <FormikControl
                        control="calculated"
                        label="Your Maximum Entitlement for Eligible Shares acquired pursuant to Champignon's Private Placement is (in CAD):"
                        name="pmMaxEntitlement"
                        type="text"
                        handleCalc={ pmCalcMaxEntitlement() }
                      />
                      <FormikControl
                        control="calculated"
                        label="Your Adjusted Damages for Eligible Shares acquired pursuant to Champignon's Private Placement is (in CAD):"
                        name="pmAdjustedDamages"
                        type="text"
                        handleCalc={ pmCalcAdjustedDamages() }
                      />
                    </>
                  }



                  <FormikControl
                    control="radioGroup"
                    label="Did you acquire Shares in the secondary market (i.e., on the CSE, FSE or OTC) during the Class Period which you held until at least February 17, 2021, calculated using LIFO?"
                    name="smAcquiredShares"
                    options={ smAcquiredSharesOptions }
                  />
                  { props.values.smAcquiredShares === "Yes" &&
                    <>
                      <FormikControl
                        control="checkboxGroup"
                        label="On which exchange/market did you acquire Shares in the secondary market which you held until at least February 17, 2021, calculated using LIFO? (check all that apply)"
                        name="smMarketSharesAcquired"
                        options={ smMarketSharesAcquiredOptions }
                      />
                      <FormikControl
                        control="input"
                        label="How many Shares did you acquire in the secondary market during the Class Period which you held until at least February 17, 2021, calculated using LIFO (regardless of which exchange on which you bought them)?"
                        name="smNumberSharesAcquired"
                        type="number"
                      />
                      { !props.values.smMarketSharesAcquired.includes("CSE") &&
                        <div className="hidden">
                          <FormikControl
                            control="calculated"
                            label="How many Shares did you acquire on the CSE during the Class Period that you held until at least February 17, 2021?"
                            name="smNumberSharesAcquiredCSE"
                            type="number"
                            handleCalc={ 0 }
                          />
                          <FormikControl
                            control="calculated"
                            label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired on the CSE during the Class Period which you held until at least February 17, 2021, including any commissions paid (in CAD)?"
                            name="smWeightedAveragePricePaidCSEinCAD"
                            type="number"
                            handleCalc={ 0 }
                          />
                        </div>
                      }
                      { props.values.smMarketSharesAcquired.includes("CSE") &&
                        <>
                          <FormikControl
                            control="input"
                            label="How many Shares did you acquire on the CSE during the Class Period that you held until at least February 17, 2021?"
                            name="smNumberSharesAcquiredCSE"
                            type="number"
                          />
                          <FormikControl
                            control="input"
                            label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired on the CSE during the Class Period which you held until at least February 17, 2021, including any commissions paid (in CAD)?"
                            name="smWeightedAveragePricePaidCSEinCAD"
                            type="number"
                          />
                        </>
                      }
                      { !props.values.smMarketSharesAcquired.includes("FSE") &&
                        <div className="hidden">
                          <FormikControl
                            control="calculated"
                            label="How many Shares did you acquire on the FSE during the Class Period that you held until at least February 17, 2021?"
                            name="smNumberSharesAcquiredFSE"
                            type="number"
                            handleCalc={ 0 }
                          />
                          <FormikControl
                            control="calculated"
                            label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired on the FSE during the Class Period which you held until at least February 17, 2021, including any commissions paid (in EUR)?"
                            name="smWeightedAveragePricePaidFSEinEUR"
                            type="number"
                            handleCalc={ 0 }
                          />
                        </div>
                      }
                      { props.values.smMarketSharesAcquired.includes("FSE") &&
                        <>
                          <FormikControl
                            control="input"
                            label="How many Shares did you acquire on the FSE during the Class Period that you held until at least February 17, 2021?"
                            name="smNumberSharesAcquiredFSE"
                            type="number"
                          />
                          <FormikControl
                            control="input"
                            label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired on the FSE during the Class Period which you held until at least February 17, 2021, including any commissions paid (in EUR)?"
                            name="smWeightedAveragePricePaidFSEinEUR"
                            type="number"
                          />
                        </>
                      }
                      { !props.values.smMarketSharesAcquired.includes("OTC") &&
                        <div className="hidden">
                          <FormikControl
                            control="calculated"
                            label="How many Shares did you acquire on the OTC during the Class Period that you held until at least February 17, 2021?"
                            name="smNumberSharesAcquiredOTC"
                            type="number"
                            handleCalc={ 0 }
                          />
                          <FormikControl
                            control="calculated"
                            label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired on the OTC during the Class Period which you held until at least February 17, 2021, including any commissions paid (in USD)?"
                            name="smWeightedAveragePricePaidOTCinUSD"
                            type="number"
                            handleCalc={ 0 }
                          />
                        </div>
                      }
                      { props.values.smMarketSharesAcquired.includes("OTC") &&
                        <>
                          <FormikControl
                            control="input"
                            label="How many Shares did you acquire on the OTC during the Class Period that you held until at least February 17, 2021?"
                            name="smNumberSharesAcquiredOTC"
                            type="number"
                          />
                          <FormikControl
                            control="input"
                            label="What was the volume weighted average price you paid per Share for all Eligible Shares acquired on the OTC during the Class Period which you held until at least February 17, 2021, including any commissions paid (in USD)?"
                            name="smWeightedAveragePricePaidOTCinUSD"
                            type="number"
                          />
                        </>
                      }
                      <FormikControl
                        control="radioGroup"
                        label="Do you still hold all of your Shares acquired in the secondary market during the Class Period, calculated using LIFO?"
                        name="smStillHoldingAllShares"
                        options={ smStillHoldingAllSharesOptions }
                      />
                    </>
                  }
                  { props.values.smStillHoldingAllShares === "No" &&
                    <>
                      <FormikControl
                        control="checkboxGroup"
                        label="On which exchange/market did you dispose of your Shares acquired in the secondary market during the Class Period which you held until at least February 17, 2021 (check all that apply)?"
                        name="smMarketSharesDisposed"
                        options={ smMarketSharesDisposedOptions }
                      />
                    </>
                  }
                  { !props.values.smMarketSharesDisposed.includes("OTC") &&
                    <div className="hidden">
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC between February 17, 2021 and March 2, 2021 inclusive?"
                        name="smSharesDisposedFeb17Mar2"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC between February 17, 2021 and March 2, 2021 inclusive, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedFeb17Mar2inUSD"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC between March 3, 2021 and March 11, 2021 inclusive?"
                        name="smSharesDisposedMar3Mar11"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC between March 3, 2021 and March 11, 2021 inclusive, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedMar3Mar11inUSD"
                        type="number"
                        handleCalc={ 0 }
                          />
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC between March 12, 2021 and March 25, 2021 inclusive?"
                        name="smSharesDisposedMar12Mar25"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC between March 12, 2021 and March 25, 2021 inclusive, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedMar12Mar25inUSD"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC after March 25, 2021?"
                        name="smSharesDisposedAfterMar25"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC after March 25, 2021, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedAfterMar25inUSD"
                        type="number"
                        handleCalc={ 0 }
                      />
                    </div>
                  }
                  { props.values.smMarketSharesDisposed.includes("OTC") &&
                    <>
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC between February 17, 2021 and March 2, 2021 inclusive?"
                        name="smSharesDisposedFeb17Mar2"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC between February 17, 2021 and March 2, 2021 inclusive, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedFeb17Mar2inUSD"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC between March 3, 2021 and March 11, 2021 inclusive?"
                        name="smSharesDisposedMar3Mar11"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC between March 3, 2021 and March 11, 2021 inclusive, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedMar3Mar11inUSD"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC between March 12, 2021 and March 25, 2021 inclusive?"
                        name="smSharesDisposedMar12Mar25"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC between March 12, 2021 and March 25, 2021 inclusive, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedMar12Mar25inUSD"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the OTC after March 25, 2021?"
                        name="smSharesDisposedAfterMar25"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the OTC after March 25, 2021, without deducting commissions (in USD)?"
                        name="smWeightedAveragePriceReceivedAfterMar25inUSD"
                        type="number"
                      />
                    </>
                  }
                  { !props.values.smMarketSharesDisposed.includes("CSE") &&
                    <div className="hidden">
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the CSE between April 23, 2021 and May 6, 2021 inclusive?"
                        name="smSharesDisposedApr23May6"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the CSE between April 23, 2021 and May 6, 2021 inclusive, without deducting commissions (in CAD)?"
                        name="smWeightedAveragePriceReceivedApr23May6inCAD"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the CSE after May 6, 2021?"
                        name="smSharesDisposedAfterMay6"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the CSE after May 6, 2021, without deducting commissions (in CAD)?"
                        name="smWeightedAveragePriceReceivedAfterMay6inCAD"
                        type="number"
                        handleCalc={ 0 }
                      />
                    </div>
                  }
                  { props.values.smMarketSharesDisposed.includes("CSE") &&
                    <>
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the CSE between April 23, 2021 and May 6, 2021 inclusive?"
                        name="smSharesDisposedApr23May6"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the CSE between April 23, 2021 and May 6, 2021 inclusive, without deducting commissions (in CAD)?"
                        name="smWeightedAveragePriceReceivedApr23May6inCAD"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the CSE after May 6, 2021?"
                        name="smSharesDisposedAfterMay6"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the CSE after May 6, 2021, without deducting commissions (in CAD)?"
                        name="smWeightedAveragePriceReceivedAfterMay6inCAD"
                        type="number"
                      />
                    </>
                  }
                  { !props.values.smMarketSharesDisposed.includes("FSE") &&
                    <div className="hidden">
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the FSE between April 27, 2021 and May 11, 2021 inclusive?"
                        name="smSharesDisposedApr27May11"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the FSE between April 27, 2021 and May 11, 2021 inclusive, without deducting commissions (in EUR)?"
                        name="smWeightedAveragePriceReceivedApr27May11inEUR"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the FSE after May 11, 2021?"
                        name="smSharesDisposedAfterMay11"
                        type="number"
                        handleCalc={ 0 }
                      />
                      <FormikControl
                        control="calculated"
                        label="What was the weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the FSE after May 11, 2021, without deducting commissions (in EUR)?"
                        name="smWeightedAveragePriceReceivedAfterMay11inEUR"
                        type="number"
                        handleCalc={ 0 }
                      />
                    </div>
                  }
                  { props.values.smMarketSharesDisposed.includes("FSE") &&
                    <>
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the FSE between April 27, 2021 and May 11, 2021 inclusive?"
                        name="smSharesDisposedApr27May11"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the volume weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the FSE between April 27, 2021 and May 11, 2021 inclusive, without deducting commissions (in EUR)?"
                        name="smWeightedAveragePriceReceivedApr27May11inEUR"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="How many Shares acquired in the secondary market during the Class Period did you dispose of on the FSE after May 11, 2021?"
                        name="smSharesDisposedAfterMay11"
                        type="number"
                      />
                      <FormikControl
                        control="input"
                        label="What was the weighted average price you received per Share upon disposition of Shares acquired in the secondary market during the Class Period which you disposed of on the FSE after May 11, 2021, without deducting commissions (in EUR)?"
                        name="smWeightedAveragePriceReceivedAfterMay11inEUR"
                        type="number"
                      />
                    </>
                  }
                  { props.values.smStillHoldingAllShares === "No" &&
                    <>
                      { !props.values.smMarketSharesAcquired.includes("CSE") &&
                        <div className="hidden">
                          <FormikControl
                            control="calculated"
                            label="How many Shares acquired on the CSE during the Class Period are you still holding?"
                            name="smSharesStillHoldingCSE"
                            type="number"
                            handleCalc={ 0 }
                          />
                        </div>
                      }
                      { props.values.smMarketSharesAcquired.includes("CSE") &&
                        <FormikControl
                          control="input"
                          label="How many Shares acquired on the CSE during the Class Period are you still holding?"
                          name="smSharesStillHoldingCSE"
                          type="number"
                        />
                      }
                      { !props.values.smMarketSharesAcquired.includes("FSE") &&
                        <div className="hidden">
                          <FormikControl
                            control="calculated"
                            label="How many Shares acquired on the FSE during the Class Period are you still holding?"
                            name="smSharesStillHoldingFSE"
                            type="number"
                            handleCalc={ 0 }
                          />
                        </div>
                      }
                      { props.values.smMarketSharesAcquired.includes("FSE") &&
                        <FormikControl
                          control="input"
                          label="How many Shares acquired on the FSE during the Class Period are you still holding?"
                          name="smSharesStillHoldingFSE"
                          type="number"
                        />
                      }
                      { !props.values.smMarketSharesAcquired.includes("OTC") &&
                        <div className="hidden">
                          <FormikControl
                            control="calculated"
                            label="How many Shares acquired on the OTC during the Class Period are you still holding?"
                            name="smSharesStillHoldingOTC"
                            type="number"
                            handleCalc={ 0 }
                          />
                        </div>
                      }
                      { props.values.smMarketSharesAcquired.includes("OTC") &&
                        <FormikControl
                          control="input"
                          label="How many Shares acquired on the OTC during the Class Period are you still holding?"
                          name="smSharesStillHoldingOTC"
                          type="number"
                        />
                      }
                    </>
                  }
                  { props.values.smAcquiredShares === "Yes" &&
                    <>
                      <FormikControl
                        control="calculated"
                        label="Your Maximum Entitlement for Eligible Shares acquired in the secondary market during the Class Period is (in CAD):"
                        name="smMaxEntitlement"
                        type="text"
                        handleCalc={ smCalcMaxEntitlement() }
                      />
                      <FormikControl
                        control="calculated"
                        label="Your Adjusted Damages for Eligible Shares acquired in the secondary market during the Class Period is (in CAD):"
                        name="smAdjustedDamages"
                        type="text"
                        handleCalc={ smCalcAdjustedDamages() }
                      />
                    </>
                  }


                  { ( props.values.pmAcquiredShares === "Yes" || props.values.smAcquiredShares === "Yes" ) &&
                    <FormikControl
                      control="calculated"
                      label="Your Compensable Loss for all Eligible Shares is (in CAD):"
                      name="compensableLoss"
                      type="text"
                      handleCalc={ calcCompensableLoss() }
                    />
                  }
                </div>
              </div>
              <div className="my-4">
                <FormikControl
                  control="boolean"
                  label="I certify that the above information is correct."
                  name="dataVerifiable"
                />
              </div>
              <div className="my-4">
                <FormikControl
                  control="boolean"
                  label={ <>I accept the <Link className="underline" to="/legal/terms-of-use">Terms of Use</Link> and <Link className="underline" to="/legal/privacy-policy">Privacy Policy</Link>.</> }
                  name="acceptedLegal"
                />
              </div>
              { ( props.values.jointAccount === "Yes" && props.values.authorityClaimantBehalf === "Yes" ) &&
                <button type="submit" className="w-32 h-12 mt-2 bg-blue-dark text-white">
                  { props.isSubmitting ? 'Sending...' : 
                    (
                      <div className="flex flex-row justify-center items-center">
                        <span className="flex-inital pr-2 font-thin">Submit</span>
                        <span className="flex-inital">
                          <WhiteArrow className="w-6" />
                        </span>
                      </div>
                    )
                  }
                </button>
              }
              { ( props.values.jointAccount === "Yes" && props.values.authorityClaimantBehalf === "No" ) &&
                <>
                  <button type="submit" className="w-32 h-12 mt-2 bg-blue-dark text-white disabled:bg-gray-700 disabled:opacity-50" disabled>
                    <div className="flex flex-row justify-center items-center">
                      <span className="flex-inital pr-2 font-thin">Submit</span>
                      <span className="flex-inital">
                        <WhiteArrow className="w-6" />
                      </span>
                    </div>
                  </button>
                </>
              }
              { ( props.values.jointAccount === "No" || props.values.jointAccount === "" || ( props.values.jointAccount === "Yes" && props.values.authorityClaimantBehalf === "" ) ) &&
                <button type="submit" className="w-32 h-12 mt-2 bg-blue-dark text-white">
                  { props.isSubmitting ? 'Sending...' : 
                    (
                      <div className="flex flex-row justify-center items-center">
                        <span className="flex-inital pr-2 font-thin">Submit</span>
                        <span className="flex-inital">
                          <WhiteArrow className="w-6" />
                        </span>
                      </div>
                    )
                  }
                </button>
              }
              { props.status &&
                <div>
                  { props.status.success &&
                    <div className="my-4 text-green-700 text-lg font-thin">
                      <span>Thank you for your submission, we'll update you as soon as we can.</span>
                    </div>
                  }
                  { props.status.success === false &&
                    <div className="my-4 text-red-700 text-lg font-thin">
                      <span>
                        Something went wrong. Please contact us directly at <a className="underline" href="mailto:braxia@knd.law">braxia@knd.law</a>.
                      </span>
                    </div>
                  }
                </div>
              }
            </div>
          </Form>
        )}
      </Formik>
    </section>
  )
}

export default BraxiaSettlementForm;