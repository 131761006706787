import React from "react";
import { graphql } from "gatsby";

import CallToAction from "../components/CallToAction/CallToAction";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import MarkdownHero from "../components/Hero/MarkdownHero";
import Post from "../components/Post/Post";
import SingleImage from "../components/SingleMedia/SingleImage";

import BraxiaSettlementForm from "../components/Form/Settlements/BraxiaSettlementForm";


const SettlementTemplate = ({
  data
}) => {
  const {
    markdownRemark: {
      frontmatter: {
        date,
        description,
        location,
        title,
        template,
        settledCase,
        altText
      },
      html
    }
  } = data;

  return (
    <Layout
      title={ title }
      description={ description }
    >
      <Header
        colorScheme="light"
      />

      <MarkdownHero
        title={ title }
        template={ template }
        location={ location }
        date={ date }
        colorScheme="light"
      />

      <Post
        body={ html }
        date={ date }
        template={ template }
      />

      { settledCase === 'braxia-scientific-corp' &&
        <BraxiaSettlementForm
          caseFormTitle={ title }
        />
      }

      <CallToAction />

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        location
        title
        template
        settledCase
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        altText
      }
    }
  }
`;

export default SettlementTemplate;